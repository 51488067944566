import React, { useState, useEffect } from 'react'

import brokenImg from '../../../assets/img/broken-image-general.png';

export const ProductosPedido = (props) => {

  var Productos = props.item

  const handleImageError = (event) => {
    event.target.src = brokenImg; // Replace with your default image URL
  };

  const agency = (Productos.Agencia === 'CCS' && props.ZonasVenta === '001,003,004,CCS')
    ? ' - CARACAS CCS'
    : (Productos.Agencia !== 'CCS' && props.ZonasVenta === '001,003,004,CCS')
      ? ' - MARACAIBO'
      : '';

  return (
    <tr className='text-center'>
      <td>
        <img src={`https://www.belmeny.com/catalogo/${Productos.Codigo}.png`} onError={handleImageError} alt="" />
      </td>
      <td className='text-danger fw-bold'>
        {Productos.Codigo} {agency}
      </td>
      <td className='fw-bold'>
        {Productos.Nombre}
      </td>
      <td className='text-success fw-bold'>
        ${Productos.PrecioUnit.toFixed(2)}
      </td>
      <td className='fw-bold'>
        {Productos.Cantidad}
      </td>
      <td className='text-success fw-bold'>
        ${Productos.Subtotal.toFixed(2)}
      </td>
    </tr>
  )
}
